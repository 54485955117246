import Layout from "../components/layout";
import React from "react";
import {graphql, Link, navigate} from "gatsby";
import '../assets/styles/pages/news-lobby.scss';
import Pagination from "react-js-pagination";
import {mapGartner, mapStickyBanner} from '../common/utils';
import {get} from "lodash";
import striptags from 'striptags';
import CatoLink from '../components/cato-link';
import {LANG} from '../common/consts';

const SideItem = ({path, main_image, title, company, company_logo, hr, style = {}, className = ''}) => (
    <CatoLink path={path} className={`side-item ${className}`} style={style}>
        {hr && <hr />}
        {main_image && <img src={main_image} alt={''}/>}
        <div className={'side-headline'}>{title}</div>
        <div className={'person-name'}>{company}</div>
        {company_logo && <img className={'logo'} src={company_logo} alt={''} />}
    </CatoLink>
);

const Row = ({date,author,title,logo,content,link}) => (
    <div className={'row'}>
        <div className={'date-row'}>{date}<span>•</span>{author}</div>
        {logo && <img className={'logo'} src={logo} alt={''} />}
        <h3>
            <CatoLink path={link} setHTML={title}/>
            <CatoLink path={link} className={'mobile-only read-more-icon'}/>
        </h3>
        <div className={'content'}>
            {content && <span style={{paddingRight: '10px'}}>{content}</span>}
            <span>
                <CatoLink path={link}>Read more</CatoLink>
            </span>
        </div>
        <hr />
    </div>
);

const NewsLobby = (props) => {
    const {pageContext} = props;
    const {data} = props;
    const {wpPage, allWpNewsItem, featuredStories} = data;

    const showDots = pageContext.currentPage + 3 < pageContext.newsPostsNum / pageContext.limit ? 'show-dots' : '';

    let featured = featuredStories.nodes;
    if(featured.length) {
        featured = featured.map((node) => ({
            id: node.id,
            title: node.title,
            path: node.content ? node.path : get(node, 'newsPosts.readMoreUrl'),
            main_image: get(node.featuredMedia, 'localFile.publicURL'),
            company: get(node, 'newsPosts.companyName') || 'Cato Networks',
            company_logo: get(node, 'newsPosts.logo.sourceUrl'),
        }));
    }

    const SideMenuTop = () => !featured[0] ? null : <SideItem {...featured[0]} />;
    const SideMenuBottom = () => !featured[1] ? null : <SideItem hr className={'bottom'} {...featured[1]} />;

    const getContent = (content) => {
        if(content.indexOf('[&hellip;]') > 0) {
            content = '';
        } else if(content.length > 0) {
            content = striptags(content);
        }
        return content;
    };

    const gartner = mapGartner(wpPage);

    const stickyBanner = mapStickyBanner(wpPage);
    // console.log(allWpNewsItem.edges)

    return (
        <Layout
            className="news-lobby"
            whiteMenu={true}
            lang={props.pageContext.lang}
            seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
            seoPath={props.location.pathname}
            gartner={gartner}
            wpid={wpPage.databaseId}

            {...{stickyBanner}}>
            <div className="bread-crumbs">
                <div>
                    <Link to="/">Home</Link>
                    <span> > </span>
                    <span>News</span>
                </div>
            </div>
            <div className={'container'}>
                <h1>News</h1>
                <div className={'news-container'}>
                    <div className={'side desktop-only'}>
                        <SideMenuTop />
                        <SideMenuBottom />
                    </div>
                    <div className={'side mobile-only'}>
                        <SideMenuTop />
                    </div>
                    <div className={'rows'}>
                        {
                            allWpNewsItem.edges.map(( {node: v} , k) => (
                                <React.Fragment key={k}>
                                    {featured.find(x => x.id === v.id) ? null :
                                        <Row date={v.date}
                                             id={v.id}
                                             author={(v.newsPosts && v.newsPosts.companyName) || 'Cato Networks'}
                                             title={v.title}
                                             // TODO uncomment this to allow automatic content summary >>
                                             // content={get(v, 'acf.custom_summary') || getFirstParagraph(v.content)}
                                             content={getContent(get(v, 'excerpt'))}
                                             link={get(v, 'newsPosts.readMoreUrl') || v.path}
                                             logo={get(v, 'newsPosts.logo.sourceUrl')}/>
                                    }
                                    {k === 4 && <div className={`side mobile-only`}><SideMenuBottom /></div>}
                                </React.Fragment>
                            ))
                        }
                         <Pagination
                             innerClass={'pagination-new'}
                             prevPageText={<span />}
                             nextPageText={<span />}
                             itemClassPrev={'prev'}
                             itemClassNext={`next ${showDots}`}
                             onChange={page => navigate(page === 1 ? `/news` : `/news/page/${page}`)}
                             activePage={pageContext.currentPage}
                             pageRangeDisplayed={7}
                             hideFirstLastPages
                             itemsCountPerPage={pageContext.limit}
                             totalItemsCount={pageContext.newsPostsNum} />
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default NewsLobby;

export const pageQuery = graphql`
    query newsLobbyPageQuery($id: String!, $limit: Int!, $skip: Int!, $exclude: [Int], $lang: WpLanguageCodeEnum!) {
        wpPage(id: { eq: $id }) {
            ...YoastData
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM DD, YYYY")
            path: uri
            pageSeoTitle {
                pageSeoTitle
            }
            postLobbySidebars {
                sidebarBanners {
                    sidebarImage {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                    sidebarLink
                }
            }
            ...StickyBanner
            ...GartnerStrip
        }
        featuredStories: allWpNewsItem(
            sort: {fields: date, order: DESC}
            filter: {
                status: {eq: "publish"}
                newsPosts: {featuredItem: {eq: true}}
                language: {code: {eq: $lang}}
            }
            limit: 2
            skip: 0
        ){
            nodes {
                title
                path: uri
                content
                excerpt
                date(formatString: "MMMM D, YYYY")
                id
                newsPosts {
                    readMoreUrl
                    logo {
                        sourceUrl
                    }
                    featuredItem
                    companyName
                }
                featuredImage {
                    node {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
        allWpNewsItem (
            sort: {fields: date, order: DESC}
            filter: {status: { eq: "publish"}, databaseId: {nin: $exclude}, language: {code: {eq: $lang}}}
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    title
                    path: uri
                    content
                    date(formatString: "MMMM D, YYYY")
                    id
                    excerpt
                    newsPosts {
                        readMoreUrl
                        logo {
                            sourceUrl
                        }
                        featuredItem
                        companyName
                    }
                    featuredImage {
                        node {
                            altText
                            localFile {
                                publicURL
                            }
                        }
                    }
                }
            }
        }
    }`;